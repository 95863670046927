import fetchWithAuthHeaders from '@motivo/styx/src/fetchWithAuthHeaders';
import { DateTime } from 'luxon';

export const ATTENDEE_VISIBILITY_THRESHOLD = 3;
export const CEREBRAL_ORGANIZATION_ID = 92;
export const BRIDGE_HEALTH_ORGANIZATION_ID = 5;

export enum AttendanceStatusEnum {
  ENTIRE_SESSION = 'entire-session',
  PARTIAL_SESSION = 'partial-session',
  NO_SHOW = 'no-show',
  RESCHEDULED = 'rescheduled',
  CANCELLED = 'cancelled',
}

export enum SessionDateFilter {
  TODAY_AND_PAST = 'TODAY',
  UPCOMING = 'UPCOMING',
  MISSED = 'MISSED',
  CANCELLED = 'CANCELLED',
}

export const getMinAndMaxDateForParams = (sessionDateFilter: string) => {
  return {
    minDate:
      sessionDateFilter === SessionDateFilter.TODAY_AND_PAST ||
      sessionDateFilter === SessionDateFilter.MISSED
        ? undefined
        : DateTime.now().toISODate(),
    maxDate:
      sessionDateFilter === SessionDateFilter.TODAY_AND_PAST ||
      sessionDateFilter === SessionDateFilter.MISSED
        ? DateTime.now().toISODate()
        : undefined,
  };
};

export const fetchAttendanceReport = async ({
  startDate,
  endDate,
  userId,
  organizationId,
  noShowOnly,
  programId,
}: {
  startDate: Date;
  endDate: Date;
  userId?: number;
  organizationId?: number;
  noShowOnly?: boolean;
  programId?: string;
}): Promise<Blob> => {
  const response = await fetchWithAuthHeaders(
    `${import.meta.env.VITE_API_URL}/reporting/attendanceReportForOrganizationAdmins`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        startDate,
        endDate,
        userId,
        organizationId,
        noShowOnly,
        programId,
      }),
    },
  );
  return response.blob();
};

export const fetchBillingReport = async ({
  month,
  year,
  organizationId,
}: {
  month: number;
  year: number;
  organizationId: number;
}): Promise<Blob> => {
  const response = await fetchWithAuthHeaders(
    `${import.meta.env.VITE_API_URL}/reporting/billingReportForOrganizationAdmins`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        month,
        year,
        organizationId,
      }),
    },
  );
  return response.blob();
};
