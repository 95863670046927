import { ProgramFilterBanner } from ':src/components/ProgramFilterBanner';
import { SESSIONS_PER_PAGE } from ':src/globalConstants';
import { getLimitAndOffsetFromPageParams } from ':src/hooks';
import usePermissions, { OrganizationAdminPermissionName } from ':src/hooks/usePermissions';
import useQueryParams from ':src/hooks/useQueryParams';
import { useQueryVariablesForSessions } from ':src/hooks/useQueryVariablesForSessions';
import useViewer from ':src/hooks/useViewer';
import { useQuery } from '@apollo/client';
import { MoCard, MoPageContainer, MoTabLink } from '@motivo/guanyin/src/components';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelectedProgramFilter } from '../programs/SelectedProgramFilterContext';
import SessionsCard from './SessionsPage/components/SessionsCard';
import { sessionsQuery } from './SessionsPage/queries';
import { SessionDateFilter } from './utils';

export const upcomingTabPath = `/sessions?minDate=${DateTime.now().toISODate()}&sessionDateFilter=UPCOMING`;
export const completedTabPath = `/sessions?maxDate=${DateTime.now().toISODate()}&sessionDateFilter=TODAY`;
export const missedTabPath = `/sessions?maxDate=${DateTime.now().toISODate()}&sessionDateFilter=MISSED`;
export const cancelledTabPath = `/sessions?maxDate=${DateTime.now().toISODate()}&sessionDateFilter=CANCELLED`;

export default function SessionsPage() {
  const history = useHistory();
  const { viewer } = useViewer();
  const { hasPermission } = usePermissions();
  const { queryParams, pushUpdateQueryParams } = useQueryParams();
  const queryVariables = useQueryVariablesForSessions();
  const {
    page: pageParam,
    minDate: minDateParam,
    maxDate: maxDateParam,
    sessionDateFilter: sessionDateFilterParam,
  } = queryParams;
  const [programFilter] = useSelectedProgramFilter();
  const page = Number(pageParam) || 1;
  const zeroBasedPage = page - 1;
  const shouldDisplaySessionNotes =
    hasPermission(OrganizationAdminPermissionName.ProgramView, programFilter?.id) &&
    sessionDateFilterParam !== SessionDateFilter.UPCOMING &&
    sessionDateFilterParam !== SessionDateFilter.CANCELLED;

  const { data: sessionData, loading: sessionLoading } = useQuery(sessionsQuery, {
    variables: {
      ...queryVariables,
      ...getLimitAndOffsetFromPageParams(zeroBasedPage, SESSIONS_PER_PAGE),
    },
    fetchPolicy: 'network-only',
  });

  const sessions = sessionData?.sessions;

  if (
    !hasPermission(OrganizationAdminPermissionName.ProgramView, programFilter?.id) &&
    !hasPermission(OrganizationAdminPermissionName.SessionsViewAll)
  ) {
    return <Redirect to="/" />;
  }

  function updateQueryParamOnTabChange({
    sessionDateFilterEnum,
    tabPath,
  }: {
    sessionDateFilterEnum: SessionDateFilter;
    tabPath: string;
  }) {
    if (minDateParam && maxDateParam) {
      pushUpdateQueryParams({
        minDate: minDateParam,
        maxDate: maxDateParam,
        sessionDateFilter: sessionDateFilterEnum as string,
      });
    } else {
      history.push(tabPath);
    }
  }

  return (
    <MoPageContainer
      maxWidth="lg"
      preContent={
        <>
          <ProgramFilterBanner />
        </>
      }
    >
      <Typography variant="h3" mb={3}>
        Sessions
      </Typography>
      <Box mb={3} display="flex" gap={3}>
        <MoTabLink
          isSelected={sessionDateFilterParam === SessionDateFilter.UPCOMING}
          onClick={() =>
            updateQueryParamOnTabChange({
              sessionDateFilterEnum: SessionDateFilter.UPCOMING,
              tabPath: upcomingTabPath,
            })
          }
        >
          Upcoming
        </MoTabLink>
        <MoTabLink
          isSelected={sessionDateFilterParam === SessionDateFilter.TODAY_AND_PAST}
          onClick={() =>
            updateQueryParamOnTabChange({
              sessionDateFilterEnum: SessionDateFilter.TODAY_AND_PAST,
              tabPath: completedTabPath,
            })
          }
        >
          Completed
        </MoTabLink>
        <MoTabLink
          isSelected={sessionDateFilterParam === SessionDateFilter.MISSED}
          onClick={() =>
            updateQueryParamOnTabChange({
              sessionDateFilterEnum: SessionDateFilter.MISSED,
              tabPath: missedTabPath,
            })
          }
        >
          Missed
        </MoTabLink>
        <MoTabLink
          isSelected={sessionDateFilterParam === SessionDateFilter.CANCELLED}
          onClick={() =>
            updateQueryParamOnTabChange({
              sessionDateFilterEnum: SessionDateFilter.CANCELLED,
              tabPath: cancelledTabPath,
            })
          }
        >
          Cancelled
        </MoTabLink>
      </Box>
      <MoCard padding="0" useMobileStyles>
        <SessionsCard
          sessionData={sessions}
          organizationId={viewer?.organizationId}
          sessionLoading={sessionLoading}
          shouldDisplaySessionNotes={shouldDisplaySessionNotes}
        />
      </MoCard>
    </MoPageContainer>
  );
}
